import { useState } from "react";
import { httpDelete, httpGet, httpPost } from "./../../axios/axiosUtils";
import { errorAlert, successAlert } from "../../utils/HelperMixins";
import { useNavigate } from "react-router-dom";
import LocalStorageComponent from "../../components/LocalStorageComponents";
import useProgressState from "../useProgressState";
import { RegisterAgent, AgentIdType } from "./utils";
import {
  AgentDetailResponse,
  AgentsOfUserResponse,
  CreateAgentResponse,
  RemoveAgentResponse,
  UploadAgentFilesResponse,
} from "../../pages/Agents/utils";
const useAgent = () => {
  const navigate = useNavigate();
  const { setFailure, setLoading, setSuccess, success, failure, loading } =
    useProgressState();
  const [removeLoading, setRemoveLoading] = useState<boolean>(false);
  const getAgent = async (shouldLoad: boolean) => {
    try {
      if (shouldLoad) setLoading();
      const response: AgentsOfUserResponse = await httpGet(
        "/agent/getAgentsOfUser",
      );
      if (response.status === 200) {
        successAlert(response.message);
        setSuccess();
        return response;
      }
      return false;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setFailure();
      errorAlert(error.response.data.message);
      if (error.response.status === 401) {
        LocalStorageComponent.deleteAll();
        navigate("/login");
      }
      return false;
    }
  };
  const getAgentDetails = async (data: AgentIdType) => {
    try {
      setLoading();
      const response: AgentDetailResponse = await httpPost(
        "/agent/getAgentDetails",
        data,
      );
      if (response.status === 200) {
        setSuccess();
        return response;
      }
      return false;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setFailure();
      errorAlert(error.response.data.message);
      if (error.response.status === 401) {
        LocalStorageComponent.deleteAll();
        navigate("/login");
      }
      return false;
    }
  };
  const registertAgent = async (data: RegisterAgent) => {
    try {
      setLoading();
      const response: CreateAgentResponse = await httpPost(
        "/agent/createAgent",
        data,
      );
      if (response.status === 200) {
        successAlert(response.message);
        setSuccess();
        return response;
      }
      return false;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setFailure();
      errorAlert(error.response.data.message);
      if (error.response.status === 401) {
        LocalStorageComponent.deleteAll();
        navigate("/login");
      }
      return false;
    }
  };
  const removeAgent = async (data: AgentIdType) => {
    try {
      setRemoveLoading(true);
      const response: RemoveAgentResponse = await httpDelete(
        "/agent/removeAgent",
        data,
      );
      if (response.status === 200) {
        // successAlert(response.message);
        setRemoveLoading(false);
        return true;
      }
      return false;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setRemoveLoading(false);
      errorAlert(error.response.data.message);
      if (error.response.status === 401) {
        LocalStorageComponent.deleteAll();
        navigate("/login");
      }
      return false;
    }
  };
  const uploadAgentFiles = async (data: FormData) => {
    try {
      setLoading();
      const response: UploadAgentFilesResponse = await httpPost(
        "/agent/uploadAgentFiles",
        data,
      );
      if (response.status === 200) {
        // successAlert(response.message);
        setSuccess();
        return response;
      }
      return false;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setFailure();
      errorAlert(error.response.data.message);
      if (error.response.status === 401) {
        LocalStorageComponent.deleteAll();
        navigate("/login");
      }
      return false;
    }
  };
  return {
    success,
    failure,
    loading,
    removeLoading,
    getAgent,
    registertAgent,
    removeAgent,
    uploadAgentFiles,
    getAgentDetails,
  };
};
export default useAgent;
