import React, { useState } from "react";
import { Nav, Image, Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import {
  Speedometer,
  PersonFill,
  Headset,
  Question,
  GearFill,
  BoxArrowRight,
  EnvelopeFill,
} from "react-bootstrap-icons";
import { SideMenuLogoWhite } from "../constants/svg";
import LocalStorageComponent from "../components/LocalStorageComponents";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const SideMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { signOut } = useAuth();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [hoverOnDashboard, setHoverOnDashboard] = useState<boolean>(false);
  const [hoverOnProfile, setHoverOnProfile] = useState<boolean>(false);
  const [hoverOnAgents, setHoverOnAgents] = useState<boolean>(false);
  const [hoverOnNotification, setHoverOnNotification] =
    useState<boolean>(false);
  const [hoverOnHelp, setHoverOnHelp] = useState<boolean>(false);
  const [hoverOnSettings, setHoverOnSettings] = useState<boolean>(false);
  const [hoverOnLogout, setHoverOnLogout] = useState<boolean>(false);
  return (
    <>
      <Nav className="col-md-2 sidemenu flex-column">
        <Link to="/dashboard" className="sidemenu-logo-wrapper">
          <Image
            src={SideMenuLogoWhite}
            alt="Omni Service Logo"
            className="omni-sidemenu"
          />
        </Link>
        <Nav.Item
          onMouseEnter={() => setHoverOnDashboard(true)}
          onMouseLeave={() => setHoverOnDashboard(false)}
          className={
            splitLocation[1] === "dashboard"
              ? "sidemenu-item active"
              : "sidemenu-item"
          }
        >
          <Speedometer
            color={
              splitLocation[1] === "dashboard" || hoverOnDashboard
                ? "#ffffff"
                : "#949494"
            }
            size={25}
            className="side-menu-icons"
          />
          <Link
            to="/dashboard"
            className={
              splitLocation[1] === "dashboard" || hoverOnDashboard
                ? "sidemenu-links active-font"
                : "sidemenu-links"
            }
          >
            Dashboard
          </Link>
        </Nav.Item>

        <Nav.Item
          onMouseEnter={() => setHoverOnProfile(true)}
          onMouseLeave={() => setHoverOnProfile(false)}
          className={
            splitLocation[1] === "profile"
              ? "sidemenu-item active"
              : "sidemenu-item"
          }
        >
          <PersonFill
            color={
              splitLocation[1] === "profile" || hoverOnProfile
                ? "#ffffff"
                : "#949494"
            }
            size={25}
            className="side-menu-icons"
          />
          <Link
            to="/profile"
            className={
              splitLocation[1] === "profile" || hoverOnProfile
                ? "sidemenu-links active-font"
                : "sidemenu-links"
            }
          >
            Profile
          </Link>
        </Nav.Item>

        <Nav.Item
          onMouseEnter={() => setHoverOnAgents(true)}
          onMouseLeave={() => setHoverOnAgents(false)}
          className={
            splitLocation[1] === "agent" ||
            splitLocation[1] === "agent-management"
              ? "sidemenu-item active"
              : "sidemenu-item"
          }
        >
          <Headset
            color={
              splitLocation[1] === "agent" ||
              splitLocation[1] === "agent-management" ||
              hoverOnAgents
                ? "#ffffff"
                : "#949494"
            }
            size={25}
            className="side-menu-icons"
          />
          <Link
            to="/agent"
            className={
              splitLocation[1] === "agent" ||
              splitLocation[1] === "agent-management" ||
              hoverOnAgents
                ? "sidemenu-links active-font"
                : "sidemenu-links"
            }
          >
            Agent
          </Link>
        </Nav.Item>

        <Nav.Item
          onMouseEnter={() => setHoverOnNotification(true)}
          onMouseLeave={() => setHoverOnNotification(false)}
          className={
            splitLocation[1] === "inbox"
              ? "sidemenu-item active"
              : "sidemenu-item"
          }
        >
          <EnvelopeFill
            color={
              splitLocation[1] === "inbox" || hoverOnNotification
                ? "#ffffff"
                : "#949494"
            }
            size={25}
            className="side-menu-icons"
          />

          <Link
            to="/inbox"
            className={
              splitLocation[1] === "inbox" || hoverOnNotification
                ? "sidemenu-links active-font"
                : "sidemenu-links"
            }
          >
            Inbox
          </Link>
        </Nav.Item>

        <Nav.Item
          onMouseEnter={() => setHoverOnHelp(true)}
          onMouseLeave={() => setHoverOnHelp(false)}
          className={
            splitLocation[1] === "help-and-support"
              ? "sidemenu-item active"
              : "sidemenu-item"
          }
        >
          <Question
            color={
              splitLocation[1] === "help-and-support" || hoverOnHelp
                ? "#ffffff"
                : "#949494"
            }
            size={25}
            className="side-menu-icons"
          />
          <Link
            to="/help-and-support"
            className={
              splitLocation[1] === "help-and-support" || hoverOnHelp
                ? "sidemenu-links  active-font"
                : "sidemenu-links"
            }
          >
            Help and support
          </Link>
        </Nav.Item>

        <Nav.Item
          onMouseEnter={() => setHoverOnSettings(true)}
          onMouseLeave={() => setHoverOnSettings(false)}
          className={
            splitLocation[1] === "settings"
              ? "sidemenu-item active"
              : "sidemenu-item"
          }
        >
          <GearFill
            color={
              splitLocation[1] === "settings" || hoverOnSettings
                ? "#ffffff"
                : "#949494"
            }
            size={25}
            className="side-menu-icons"
          />

          <Link
            to="/settings"
            className={
              splitLocation[1] === "settings" || hoverOnSettings
                ? "sidemenu-links  active-font"
                : "sidemenu-links"
            }
          >
            Settings
          </Link>
        </Nav.Item>
        <Nav.Item
          onMouseEnter={() => setHoverOnLogout(true)}
          onMouseLeave={() => setHoverOnLogout(false)}
          className="sidemenu-item"
        >
          <BoxArrowRight
            color={hoverOnLogout ? "#ffffff" : "#949494"}
            size={25}
            className="side-menu-icons"
          />
          <Button
            onClick={async () => {
              const res = await signOut();
              if (res) {
                LocalStorageComponent.deleteAll();
                navigate("/login");
              }
            }}
            className={
              hoverOnLogout
                ? "sidemenu-links  active-font logout-btn"
                : "sidemenu-links logout-btn"
            }
          >
            Logout
          </Button>
        </Nav.Item>
      </Nav>
    </>
  );
};

export default SideMenu;
