import React, { useEffect, useState, useRef } from "react";
import SideMenu from "../../components/SideMenu";
import Form from "react-bootstrap/Form";
import { PencilSquare } from "react-bootstrap-icons";
import "./profile-styles.css";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { userLoggedIn } from "../../utils/HelperMixins";
import useProfile from "../../hooks/useProfile";
import { country, industry } from "../../constants/arrays";
import useUpload from "../../hooks/useUpload";
import { UpdateProfile } from "./utils";
function Profile() {
  const navigate = useNavigate();
  const { getProfileLoading, updateProfileLoading, getProfile, updateProfile } =
    useProfile();
  const { uploadLogo, uploadFile } = useUpload();
  const inputRef = useRef<HTMLInputElement>(null);
  const [businessLogo, setBusinessLogo] = useState<string>("");
  const [businessName, setBusinessName] = useState<string>("");
  const [businessEmail, setBusinessEmail] = useState<string>("");
  const [userCountry, setUserCountry] = useState<string>("");
  const [userIndustry, setUserIndustry] = useState<string>("");
  const [website, setWebsite] = useState<string>("");
  const [linkedInLink, setLinkedInLink] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [role, setRole] = useState<string>("");

  useEffect(() => {
    if (!userLoggedIn()) {
      navigate("/login");
    }
    document.title = "Profile";
  }, []);

  useEffect(() => {
    getProfileRequest(true);
  }, []);

  const getProfileRequest = async (isLoading: boolean) => {
    const res = await getProfile(isLoading);
    if (res) {
      setBusinessLogo(res.BusinessLogo);
      setBusinessName(res.BusinessName);
      setBusinessEmail(res.BusinessEmail);
      setUserCountry(res.Country);
      setUserIndustry(res.Industry ?? "");
      setWebsite(res.BusinessWebsite ?? "");
      setLinkedInLink(res.LinkedinLink ?? "");
      setPhoneNumber(res.Phone ?? "");
      setName(res.FullName ?? "");
      setRole(res.RoleInCompany ?? "");
    }
  };
  const handleUpload = () => {
    inputRef.current?.click();
  };
  const handleChange = async () => {
    if (inputRef.current?.files?.length) {
      const formData: FormData = new FormData();
      formData.append("file", inputRef.current?.files[0]);
      const res = await uploadFile(formData);
      if (res) {
        getProfileRequest(false);
      }
    }
  };
  const handleSubmit = async () => {
    const data: UpdateProfile = {
      Country: userCountry,
      Industry: userIndustry,
      Phone: phoneNumber,
      BusinessWebsite: website,
      LinkedinLink: linkedInLink,
      FullName: name,
      RoleInCompany: role,
    };

    const res = await updateProfile(data);
    if (res) {
      getProfileRequest(false);
    }
  };
  return (
    <div className="col-md-12">
      <div className="row mr-0">
        <SideMenu />
        <div className="col-md-10 main-container fixed-container">
          {!getProfileLoading ? (
            <div className="omni-content-wrapper">
              <div className="profile-pic-section">
                <div className="personal-info">
                  <div className="user-img-wrapper">
                    {!uploadLogo ? (
                      <>
                        {businessLogo ? (
                          <img
                            src={businessLogo}
                            alt={businessName + " Logo"}
                            className="profile-pic"
                          />
                        ) : (
                          <img
                            src={require("../../assets/images/placeholder-user.png")}
                            alt={businessName + " Logo"}
                            className="profile-pic"
                          />
                        )}
                      </>
                    ) : (
                      <div
                        className="spinner-border text-dark"
                        role="status"
                      ></div>
                    )}
                  </div>
                  <div className="select-logo">
                    <div className="edit-icon">
                      <input
                        ref={inputRef}
                        type="file"
                        id="business-logo"
                        className="d-none"
                        accept="image/x-png,image/jpeg"
                        onChange={handleChange}
                      />
                      <div className="change-log" onClick={handleUpload}>
                        Change Business Logo
                      </div>
                      <PencilSquare size={12} color="#2f95dc" />
                    </div>
                  </div>
                  <div className="user-info-form">
                    <Form.Group className="mb-3">
                      <Form.Label className="sora-regular">
                        Business Name
                      </Form.Label>
                      <Form.Control
                        disabled
                        type="text"
                        placeholder="Business Name"
                        className="p-3 sora-regular"
                        value={businessName}
                      />
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label className="sora-regular">
                        Business Email
                      </Form.Label>
                      <Form.Control
                        type="email"
                        disabled
                        placeholder="Business Email"
                        className="p-3 sora-regular"
                        value={businessEmail}
                      />
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label className="sora-regular">Country</Form.Label>
                      <Form.Select
                        className="p-3 sora-regular"
                        onChange={(e) => setUserCountry(e.target.value)}
                        value={userCountry}
                      >
                        <option>Select Country</option>
                        {country.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </Form.Select>
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="sora-regular">Industry</Form.Label>
                      <Form.Select
                        className="p-3 sora-regular"
                        onChange={(e) => setUserIndustry(e.target.value)}
                        value={userIndustry}
                      >
                        <option>Select Industry</option>
                        {industry.map((item, index) => {
                          return (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="sora-regular">Website</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Business Website"
                        className="p-3 sora-regular"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                  </div>
                </div>
              </div>
              <div className="user-info-section">
                <div className="personal-info">
                  <Form.Group className="mb-3">
                    <Form.Label className="sora-regular">
                      Business LinkedIn link
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Business LinkedIn Link"
                      className="p-3 sora-regular"
                      value={linkedInLink}
                      onChange={(e) => setLinkedInLink(e.target.value)}
                    />
                    <Form.Text className="text-muted"></Form.Text>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="sora-regular">
                      Phone Number
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Phone Number"
                      className="p-3 sora-regular"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    <Form.Text className="text-muted"></Form.Text>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="sora-regular">Your Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Your Name"
                      className="p-3 sora-regular"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <Form.Text className="text-muted"></Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className="sora-regular">
                      Position In Company
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Position In Company"
                      className="p-3 sora-regular"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                    />
                    <Form.Text className="text-muted"></Form.Text>
                  </Form.Group>
                  <Button
                    variant="primary"
                    className="submit-btn p-3 w-100 sora-regular"
                    onClick={handleSubmit}
                  >
                    {!updateProfileLoading ? (
                      <span>Update</span>
                    ) : (
                      <div
                        className="spinner-border text-light"
                        role="status"
                      ></div>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center">
              <div className="spinner-border text-dark" role="status"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Profile;
