import React, { useEffect, useState } from "react";
import SideMenu from "../../components/SideMenu";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { AppIndicator, ShieldFillCheck } from "react-bootstrap-icons";
import "./setting-styles.css";
import { SwitchThumb } from "../../constants/svg";
import { useNavigate } from "react-router-dom";
import { errorAlert, userLoggedIn } from "../../utils/HelperMixins";
import useProfile from "../../hooks/useProfile";
import useAuth from "../../hooks/useAuth";
import { UpdateNotificationSettings } from "../Profile/utils";
import { UpdatePassword } from "../../context/auth/types";
function Settings() {
  const navigate = useNavigate();
  const { getProfile, updateNotificationSettings } = useProfile();
  const { updatePassord, loading } = useAuth();
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [password, setPassord] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [newMessage, setNewMessage] = useState<boolean>(false);
  const [statusUpdate, setStatusUpdate] = useState<boolean>(false);
  const [reminderNotification, setReminderNotification] =
    useState<boolean>(false);
  const [systemNotification, setSystemNotification] = useState<boolean>(false);
  useEffect(() => {
    if (!userLoggedIn()) {
      navigate("/login");
    }
    document.title = "Settings";
  }, [navigate]);
  useEffect(() => {
    getProfileRequest(false);
  }, []);

  const getProfileRequest = async (isLoading: boolean) => {
    const res = await getProfile(isLoading);
    if (res) {
      setNewMessage(res.NotificationSettings?.NewMessages ?? false);
      setStatusUpdate(res.NotificationSettings?.ReminderNotifications ?? false);
      setReminderNotification(res.NotificationSettings?.StatusUpdates ?? false);
      setSystemNotification(
        res.NotificationSettings?.SystemNotifcations ?? false,
      );
    }
  };
  const updateNewMessage = async () => {
    const data: UpdateNotificationSettings = {
      NewMessages: !newMessage,
      ReminderNotifications: reminderNotification,
      StatusUpdates: statusUpdate,
      SystemNotifcations: systemNotification,
    };
    const res = await updateNotificationSettings(data);
    if (res) {
      setNewMessage(!newMessage);
    }
  };
  const updateReminderNotis = async () => {
    const data: UpdateNotificationSettings = {
      NewMessages: newMessage,
      ReminderNotifications: !reminderNotification,
      StatusUpdates: statusUpdate,
      SystemNotifcations: systemNotification,
    };
    const res = await updateNotificationSettings(data);
    if (res) {
      setReminderNotification(!reminderNotification);
    }
  };
  const updateStatusUpdates = async () => {
    const data: UpdateNotificationSettings = {
      NewMessages: newMessage,
      ReminderNotifications: reminderNotification,
      StatusUpdates: !statusUpdate,
      SystemNotifcations: systemNotification,
    };
    const res = await updateNotificationSettings(data);
    if (res) {
      setStatusUpdate(!statusUpdate);
    }
  };
  const updateSystemsNoti = async () => {
    const data: UpdateNotificationSettings = {
      NewMessages: newMessage,
      ReminderNotifications: reminderNotification,
      StatusUpdates: statusUpdate,
      SystemNotifcations: !systemNotification,
    };
    const res = await updateNotificationSettings(data);
    if (res) {
      setSystemNotification(!systemNotification);
    }
  };
  const handleChangePassword = async () => {
    if (!currentPassword || !password || !confirmPassword) {
      errorAlert(
        "Current Password, New Password and Confirm Password required",
      );
      return;
    }
    if (password !== confirmPassword) {
      errorAlert("New Password and Confirm Password dont match");
      return;
    }
    const data: UpdatePassword = {
      CurrentPasseord: currentPassword,
      NewPassword: password,
      ConfirmPassword: confirmPassword,
    };
    const res = await updatePassord(data);
    if (res) {
      setCurrentPassword("");
      setPassord("");
      setConfirmPassword("");
    }
  };
  return (
    <div className="col-md-12">
      <div className="row mr-0">
        <SideMenu />
        <div className="col-md-10 main-container fixed-container">
          <div className="omni-content-wrapper">
            <div className="setting-section-wrapper">
              <div className="setting-content">
                <div className="section-title-wrapper">
                  <ShieldFillCheck
                    size={25}
                    // color="#2F95DC"
                    className="setting-icons"
                  />
                  <h3 className="setting-title sora-bold m-0">
                    Update Password
                  </h3>
                </div>
                <Form.Group className="mb-3">
                  <Form.Label className="sora-regular">
                    Current Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Current Password"
                    className="p-3 sora-regular"
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="sora-regular">New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="New Password"
                    className="p-3 sora-regular"
                    onChange={(e) => setPassord(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label className="sora-regular">
                    Confirm Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    className="p-3 sora-regular"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Group>

                <div className="btn-wrapper">
                  <Button
                    variant="primary"
                    className="submit-btn w-100 p-3 sora-regular"
                    onClick={handleChangePassword}
                  >
                    {!loading ? (
                      <span>Submit</span>
                    ) : (
                      <div
                        className="spinner-border text-light"
                        role="status"
                      ></div>
                    )}
                  </Button>
                </div>
              </div>
            </div>
            <div className="setting-section-wrapper">
              <div className="setting-content">
                <div className="section-title-wrapper">
                  <AppIndicator
                    size={25}
                    // color="#2F95DC"
                    className="setting-icons"
                  />
                  <h3 className="setting-title sora-bold m-0">
                    Notification Settings
                  </h3>
                </div>
                <div className="toggle-wrapper">
                  <div className="form-label sora-regular">New Messages</div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input toggle-btns"
                      style={{ backgroundImage: `url(${SwitchThumb})` }}
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      checked={newMessage}
                      onChange={updateNewMessage}
                    />
                  </div>
                </div>
                <div className="toggle-wrapper">
                  <div className="form-label sora-regular">Status Updates</div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input toggle-btns"
                      style={{ backgroundImage: `url(${SwitchThumb})` }}
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      checked={statusUpdate}
                      onChange={updateStatusUpdates}
                    />
                  </div>
                </div>
                <div className="toggle-wrapper">
                  <div className="form-label sora-regular">
                    Reminder Notifications
                  </div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input toggle-btns"
                      style={{ backgroundImage: `url(${SwitchThumb})` }}
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      checked={reminderNotification}
                      onChange={updateReminderNotis}
                    />
                  </div>
                </div>
                <div className="toggle-wrapper">
                  <div className="form-label sora-regular">
                    System Notifications
                  </div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input toggle-btns"
                      style={{ backgroundImage: `url(${SwitchThumb})` }}
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      checked={systemNotification}
                      onChange={updateSystemsNoti}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
