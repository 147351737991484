import { httpGet, httpPost } from "./../../axios/axiosUtils";
import { errorAlert, successAlert } from "../../utils/HelperMixins";
import {
  UpdateProfile,
  UpdateNotificationSettings,
  GetProfileReponse,
  UpdateProfileResponse,
} from "../../pages/Profile/utils";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LocalStorageComponent from "../../components/LocalStorageComponents";
const useProfile = () => {
  // const { setFailure, setLoading, setSuccess, success, failure, loading } = useProgressState();
  const navigate = useNavigate();
  const [getProfileLoading, setProfileLoading] = useState<boolean>(false);
  const [updateProfileLoading, setUpdateProfileLoading] =
    useState<boolean>(false);
  const getProfile = async (isLoading: boolean) => {
    try {
      if (isLoading) setProfileLoading(true);
      const response: GetProfileReponse = await httpGet("/user/getUser");
      successAlert(response.message);
      setProfileLoading(false);
      return response.user;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setProfileLoading(false);
      errorAlert(error.response.data.message);
      if (error.response.status === 401) {
        LocalStorageComponent.deleteAll();
        navigate("/login");
      }
      return false;
    }
  };
  const updateProfile = async (data: UpdateProfile) => {
    try {
      setUpdateProfileLoading(true);
      const response: UpdateProfileResponse = await httpPost(
        "/user/updateProfile",
        data,
      );
      // setProfile(response.user)
      successAlert(response.message);
      setUpdateProfileLoading(false);
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setUpdateProfileLoading(false);
      errorAlert(error.response.data.message);
      if (error.response.status === 401) {
        LocalStorageComponent.deleteAll();
        navigate("/login");
      }
      return false;
    }
  };
  const updateNotificationSettings = async (
    data: UpdateNotificationSettings,
  ) => {
    try {
      setUpdateProfileLoading(true);
      const response: UpdateProfileResponse = await httpPost(
        "/user/updateNotificationSettings",
        data,
      );
      // setProfile(response.user)
      successAlert(response.message);
      setUpdateProfileLoading(false);
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setUpdateProfileLoading(false);
      errorAlert(error.response.data.message);
      if (error.response.status === 401) {
        LocalStorageComponent.deleteAll();
        navigate("/login");
      }
      return false;
    }
  };
  return {
    getProfileLoading,
    updateProfileLoading,
    updateNotificationSettings,
    getProfile,
    updateProfile,
  };
};

export default useProfile;
