import React, { useEffect } from "react";
import AuthComponent from "../../components/AuthComponent";
import LocalStorageComponent from "../../components/LocalStorageComponents";
import { useNavigate } from "react-router-dom";
function Login() {
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Login";
    const token = LocalStorageComponent.getItemLocally("token");
    if (token !== null) {
      navigate("/dashboard");
    }
  }, []);
  return <AuthComponent authType="login" />;
}

export default Login;
