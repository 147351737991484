import React from "react";

function AgentIntegrations() {
  return (
    <pre>
      <code>API KEY: 1323jkh3jkh123kjh13jh1</code>
    </pre>
  );
}

export default AgentIntegrations;
