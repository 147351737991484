import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import useAgent from "../../hooks/useAgent";
import { errorAlert } from "../../utils/HelperMixins";

interface AddAgentModalProps {
  show: boolean;
  onHide: () => void;
}

function AddAgentModal(props: AddAgentModalProps) {
  const [agentName, setAgentName] = useState<string>("");
  const [agentDescription, setAgentDescription] = useState<string>("");
  const { registertAgent, loading } = useAgent();

  const handleSubmit = async () => {
    if (agentName === "") {
      errorAlert("Agent name is required");
      return;
    }
    if (agentDescription === "") {
      errorAlert("Agent description is required");
      return;
    }
    const data = {
      Name: agentName,
      Description: agentDescription,
    };
    const addAgentRes = await registertAgent(data);
    if (addAgentRes) {
      props.onHide();
      // Reset the form fields
      setAgentName("");
      setAgentDescription("");
    }
  };

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Agent</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3">
          <Form.Label className="sora-regular">Agent Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Agent Name"
            className="p-3 sora-regular"
            value={agentName}
            onChange={(e) => setAgentName(e.target.value)}
          />
          <Form.Text className="text-muted"></Form.Text>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label className="sora-regular">Agent Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            type="text"
            placeholder="Agent Description"
            className="p-3 sora-regular"
            value={agentDescription}
            onChange={(e) => setAgentDescription(e.target.value)}
          />
          <Form.Text className="text-muted"></Form.Text>
        </Form.Group>

        <Button
          variant="primary"
          className="submit-btn p-3 w-100 sora-regular"
          onClick={handleSubmit}
          disabled={loading}
        >
          {!loading ? (
            <span>Add Agent</span>
          ) : (
            <div className="spinner-border text-light" role="status"></div>
          )}
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export default AddAgentModal;
