import React, { ChangeEvent, useState } from "react";
import Form from "react-bootstrap/esm/Form";
// import {
//   Trash3,
// } from "react-bootstrap-icons";
import Button from "react-bootstrap/esm/Button";
import useAgent from "../../hooks/useAgent";
import { Agent } from "./utils";

interface AgentDateSetProps {
  agent: Agent;
  getAgentDetailsRequest: () => void;
}

function AgentDataSet(props: AgentDateSetProps) {
  const { agent, getAgentDetailsRequest } = props;
  const allowedFileTypes = [
    ".doc",
    ".docx",
    ".xls",
    ".xlsx",
    ".ppt",
    ".pptx",
    ".pdf",
    ".txt",
  ];
  const { loading, uploadAgentFiles } = useAgent();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFiles(Array.from(event.target.files));
    }
  };

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      // Handle case when no files are selected
      return;
    }

    const formData: FormData = new FormData();
    formData.append("bucketName", agent.Resources.S3BucketName);

    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await uploadAgentFiles(formData);
      if (response) {
        // Handle successful upload
        getAgentDetailsRequest();
      }
    } catch (error) {
      // Handle error during upload
      // console.error("Error uploading files:", error);
    }
  };

  return (
    <div className="dataset-wrapper">
      <div className="date-display-wrapper">
        <div className="dataset-title sora-bold">Uploaded Agent Files</div>
        {agent.Files.map((item, index) => {
          return (
            <div key={index} className="files-wrapper">
              <div className="file-name sora-regular">
                <a href={item.url}>{item.name}</a>
              </div>
              {/* <Trash3 size={20} color="red" /> */}
            </div>
          );
        })}
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Control
            type="file"
            accept={allowedFileTypes.join(",")}
            multiple
            onChange={handleFileChange}
          />
        </Form.Group>
        <div className="agent-data-upload">
          <Button
            variant="primary"
            className="submit-btn p-3 sora-regular"
            onClick={handleUpload}
          >
            {!loading ? (
              <span>Upload</span>
            ) : (
              <div
                className="spinner-border spinner-border-sm text-light"
                role="status"
              ></div>
            )}
          </Button>
        </div>
      </div>
      {/* <div className="date-display-wrapper">
        <div className="dataset-title sora-bold">Data Type</div>
        <div className="files-wrapper">
          <div className="file-name sora-regular">
            data-type.pdf
          </div>
          <Trash3 size={20} color="red" />
        </div>
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Control type="file" />
        </Form.Group>
        <div className="agent-data-upload">
          <Button
            variant="primary"
            className="submit-btn p-3 sora-regular"
          >
            Upload
          </Button>
        </div>
      </div> */}
    </div>
  );
}

export default AgentDataSet;
