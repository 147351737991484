import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { AuthProps } from "../shared/types";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { errorAlert, validateEmail } from "../utils/HelperMixins";
import useAuth from "../hooks/useAuth";
import { SignInProps, SignUpProps } from "../context/auth/types";
function AuthComponent(props: AuthProps) {
  const { authType } = props;
  const navigate = useNavigate();
  const { signUp, signIn, loading } = useAuth();
  const [businessName, setBusinessName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassord] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const handleLoginSubmit = async () => {
    if (email === "") {
      errorAlert("Business email is required");
    }
    if (password === "") {
      errorAlert("Password is required");
      return;
    }
    if (!validateEmail(email)) {
      errorAlert("Email not correct");
      return;
    }
    const data: SignInProps = {
      Email: email,
      Password: password,
    };
    const res = await signIn(data);
    if (res) navigate("/dashboard");
  };

  const handleSignUpSubmit = async () => {
    if (businessName === "") {
      errorAlert("Business name is required");
      return;
    }
    if (email === "") {
      errorAlert("Business email is required");
      return;
    }
    if (password === "" || confirmPassword === "") {
      errorAlert("Password and Confirm Password is required");
      return;
    }
    if (password !== confirmPassword) {
      errorAlert("Password and Confirm Password don't match");
      return;
    }
    if (!validateEmail(email)) {
      errorAlert("Email not correct");
      return;
    }
    const data: SignUpProps = {
      BusinessName: businessName,
      BusinessEmail: email,
      Password: password,
      ConfirmPassword: confirmPassword,
    };
    const res = await signUp(data);
    if (res) navigate("/login");
  };

  const handleForgotPasswordSubmit = () => {
    navigate("/login");
  };
  return (
    <div className="col-md-12 content-wrapper h-100vh">
      <div className="row mr-0 align-items-center justify-content-center">
        <div className="col-md-7 regular-flex h-100vh">
          <div className="auth-left-content-wrapper">
            <img
              src={require("../assets/images/omni-logo.png")}
              alt="Omniservice logo"
              className="auth-logo"
            />
            <p className="sora-regular">
              Omniservice differentiate businesses with GPT omnichannel
              multilingual conversational intelligent agent for customer support
              and sales.
            </p>
          </div>
        </div>
        <div className="col-md-5 regular-flex h-100vh">
          <div
            className={
              authType === "signup"
                ? "auth-right-content-wrapper scroll-section"
                : "auth-right-content-wrapper"
            }
          >
            <h4 className="sora-regular text-center">
              {authType === "login" ? "Sign In" : ""}
              {authType === "signup" ? "Sign Up" : ""}
              {authType === "forgot-password" ? "Forget Password" : ""}
            </h4>
            <div className="form-wrapper">
              {authType === "login" && (
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label className="sora-regular">
                      Email address
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      className="p-3 sora-regular"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className="sora-regular">Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      className="p-3 sora-regular"
                      onChange={(e) => setPassord(e.target.value)}
                    />
                  </Form.Group>
                  <p className="forgot-password sora-regular">
                    <Link to="/reset-passeord">Forgot Password</Link>
                  </p>
                  <Button
                    variant="primary"
                    className="submit-btn p-3 w-100 sora-regular"
                    onClick={handleLoginSubmit}
                    disabled={loading}
                  >
                    {!loading ? (
                      <span>Submit</span>
                    ) : (
                      <div
                        className="spinner-border text-light"
                        role="status"
                      ></div>
                    )}
                  </Button>
                  <p className="not-a-menber mt-2">
                    Not a Member yet? <Link to="/signup">Sign Up</Link>
                  </p>
                </Form>
              )}

              {authType === "signup" && (
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label className="sora-regular">
                      Business name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Buniness Name"
                      className="p-3 sora-regular"
                      onChange={(e) => setBusinessName(e.target.value)}
                    />
                    <Form.Text className="text-muted"></Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className="sora-regular">
                      Business Email
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Business Email"
                      className="p-3 sora-regular"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Form.Text className="text-muted"></Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className="sora-regular">Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      className="p-3 sora-regular"
                      onChange={(e) => setPassord(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label className="sora-regular">
                      Confirm Password
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirm Password"
                      className="p-3 sora-regular"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </Form.Group>

                  <Button
                    variant="primary"
                    className="submit-btn p-3 w-100 sora-regular"
                    onClick={handleSignUpSubmit}
                    disabled={loading}
                  >
                    {!loading ? (
                      <span>Submit</span>
                    ) : (
                      <div
                        className="spinner-border text-light"
                        role="status"
                      ></div>
                    )}
                  </Button>

                  <p className="not-a-menber mt-2">
                    Already have an account? <Link to="/login">Login</Link>
                  </p>
                </Form>
              )}

              {authType === "forgot-password" && (
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label className="sora-regular">
                      Email address
                    </Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      className="p-3 sora-regular"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <Form.Text className="text-muted"></Form.Text>
                  </Form.Group>

                  <Button
                    variant="primary"
                    className="submit-btn p-3 w-100 sora-regular"
                    onClick={handleForgotPasswordSubmit}
                    disabled={loading}
                  >
                    {!loading ? (
                      <span>Submit</span>
                    ) : (
                      <div
                        className="spinner-border text-light"
                        role="status"
                      ></div>
                    )}
                  </Button>

                  <p className="not-a-menber mt-2">
                    Already have an account? <Link to="/login">Login</Link>
                  </p>
                </Form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthComponent;
