import React, { useEffect } from "react";
import AuthComponent from "../../components/AuthComponent";
function SignUp() {
  useEffect(() => {
    document.title = "Sign Up";
  }, []);
  return <AuthComponent authType="signup" />;
}

export default SignUp;
