export const AGENT_STATUS = {
  available: "Available",
  inProgress: "In Progress",
  failed: "Failed",
};
export const CLOUD_FORMATION_STATUS = {
  inProgress: "in-progress",
  success: "success",
  failed: "failed",
};
