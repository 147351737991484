import React, { useEffect, useState } from "react";
import SideMenu from "../../components/SideMenu";
import { Dash, Plus, Search } from "react-bootstrap-icons";
import "./help-styles.css";
import { useNavigate } from "react-router-dom";
import { userLoggedIn } from "../../utils/HelperMixins";

function HelpAndSupport() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!userLoggedIn()) {
      navigate("/login");
    }
    document.title = "Help and Support";
  }, [navigate]);
  const [faq, setFAQ] = useState([
    {
      q: "How to create an agent",
      a: "Lorem ipsum dolor sit amet consectetur. Id feugiat dignissim  consectetur quis morbi consequat arcu. Scelerisque amet ipsum  dui ullamcorper rutrum eget ut. Ullamcorper nunc nullam scelerisque tellus varius.",
      show: false,
    },
    {
      q: "How to create an agent",
      a: "Lorem ipsum dolor sit amet consectetur. Id feugiat dignissim  consectetur quis morbi consequat arcu. Scelerisque amet ipsum  dui ullamcorper rutrum eget ut. Ullamcorper nunc nullam scelerisque tellus varius.",
      show: false,
    },
    {
      q: "How to create an agent",
      a: "Lorem ipsum dolor sit amet consectetur. Id feugiat dignissim  consectetur quis morbi consequat arcu. Scelerisque amet ipsum  dui ullamcorper rutrum eget ut. Ullamcorper nunc nullam scelerisque tellus varius.",
      show: false,
    },
  ]);
  const showFAQ = (index: number) => {
    const temp = faq;
    temp[index].show = !temp[index].show;
    setFAQ([...temp]);
  };
  return (
    <div className="col-md-12">
      <div className="row mr-0">
        <SideMenu />
        <div className="col-md-10 main-container fixed-container">
          <h2 className="page-title sora-bold">Have Any Questions?</h2>
          <div className="search-box">
            <Search size={20} color="#000000" />
            <input
              placeholder="Seach by keywords"
              className="search-input sora-regular"
              name="search"
              type="text"
            />
          </div>
          <div className="faq-content">
            {faq.map((item, index) => {
              return (
                <div className="faq-wrapper" key={index}>
                  <div className="faq">
                    <div className="faq-title sora-bold">{item.q}</div>
                    <div
                      className="expand-hide-btn"
                      onClick={() => {
                        showFAQ(index);
                      }}
                    >
                      {!item.show && <Plus size={40} color="#ffffff" />}
                      {item.show && <Dash size={40} color="#ffffff" />}
                    </div>
                  </div>
                  {item.show && (
                    <div className="faq-answer sora-regular">{item.a}</div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpAndSupport;
