import React from "react";
import { UserContext } from "./app/context/auth";
import Routers from "./app/router";
import useAuth from "./app/hooks/useAuth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  const { userData } = useAuth();
  return (
    <UserContext.Provider value={{ userData }}>
      <Routers />
      <ToastContainer />
    </UserContext.Provider>
  );
}

export default App;
