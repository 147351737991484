import { httpPost } from "./../../axios/axiosUtils";
// import useProgressState from "../useProgressState";
import { errorAlert, successAlert } from "../../utils/HelperMixins";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import LocalStorageComponent from "../../components/LocalStorageComponents";
import useProgressState from "../useProgressState";
import {
  UploadAgentFilesResponse,
  UploadFilesResponse,
} from "../../pages/Agents/utils";
const useUpload = () => {
  const { setFailure, setLoading, setSuccess, success, failure, loading } =
    useProgressState();
  const navigate = useNavigate();
  const [uploadLogo, setUploadLogo] = useState<boolean>(false);
  const uploadFile = async (data: FormData) => {
    try {
      setUploadLogo(true);
      const response: UploadFilesResponse = await httpPost("/uploadLogo", data);
      if (response.status) {
        setUploadLogo(false);
        return true;
      }
      return false;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setUploadLogo(false);
      errorAlert(error.response.data.message);
      if (error.response.status === 401) {
        LocalStorageComponent.deleteAll();
        navigate("/login");
      }
      return false;
    }
  };
  const uploadAgentFiles = async (data: FormData) => {
    try {
      setLoading();
      const response: UploadAgentFilesResponse = await httpPost(
        "/uploadAgentFiles",
        data,
      );
      if (response.status) {
        successAlert(response.message);
        setSuccess();
        return response;
      }
      return false;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setFailure();
      errorAlert(error.response.data.message);
      if (error.response.status === 401) {
        LocalStorageComponent.deleteAll();
        navigate("/login");
      }
      return false;
    }
  };
  return {
    uploadLogo,
    success,
    failure,
    loading,
    uploadFile,
    uploadAgentFiles,
  };
};

export default useUpload;
