import React, { useEffect } from "react";
import SideMenu from "../../components/SideMenu";
import "./dashboard-styles.css";
import { useNavigate } from "react-router-dom";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  // ResponsiveContainer,
} from "recharts";
import { userLoggedIn } from "../../utils/HelperMixins";
function Dashboard() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!userLoggedIn()) {
      navigate("/login");
    }
    document.title = "Dashboard";
  }, [navigate]);
  const data = [
    {
      name: "May 1",
      uv: 1000,
    },
    {
      name: "May ",
      uv: 3000,
    },
    {
      name: "May 7",
      uv: 2000,
    },
    {
      name: "May 9",
      uv: 2780,
    },
    {
      name: " May 13",
      uv: 1890,
    },
    {
      name: "May 16",
      uv: 2390,
    },
    {
      name: "May 17",
      uv: 3490,
    },
  ];

  return (
    <div className="col-md-12">
      <div className="row mr-0">
        <SideMenu />
        <div className="col-md-10 main-container fixed-container">
          <div className="user-greetings text-right">
            Hi, <span className="sora-bold">Mukash Wasti!</span>{" "}
          </div>
          <div className="dashboard-content-wrapper">
            <div className="page-title sora-bold">Dashboard</div>
            <div className="dashboard-content">
              <div className="dashboard-section">
                <div className="dashboard-section-title sora-regular">
                  Total Agents
                </div>
                <div className="section-value sora-bold">1</div>
              </div>
              <div className="dashboard-section">
                <div className="dashboard-section-title sora-regular">
                  Total Integration
                </div>
                <div className="section-value sora-bold">3</div>
              </div>
              <div className="dashboard-section">
                <div className="dashboard-section-title sora-regular">
                  Total usage this month
                </div>
                <div>
                  <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                  </LineChart>
                </div>
              </div>
              <div className="dashboard-section">
                <div className="dashboard-section-title sora-regular">
                  Top asked prompt
                </div>
                <div className="prompts">
                  <div className="prompt-header-title sora-bold">Prompt</div>
                  <div className="prompt-header-title sora-bold">Count</div>
                </div>
                <div className="prompts">
                  <div className="prompt-data sora-regular">
                    I cant make payments
                  </div>
                  <div className="prompt-data sora-regular">45</div>
                </div>
                <div className="prompts">
                  <div className="prompt-data sora-regular">
                    I cant make payments
                  </div>
                  <div className="prompt-data sora-regular">45</div>
                </div>
                <div className="prompts">
                  <div className="prompt-data sora-regular">
                    I cant make payments
                  </div>
                  <div className="prompt-data sora-regular">45</div>
                </div>
                <div className="prompts">
                  <div className="prompt-data sora-regular">
                    I cant make payments
                  </div>
                  <div className="prompt-data sora-regular">45</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
