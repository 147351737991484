import React, { useEffect } from "react";
import AuthComponent from "../../components/AuthComponent";
function ResetPassword() {
  useEffect(() => {
    document.title = "Reset Password";
  }, []);
  return <AuthComponent authType="forgot-password" />;
}

export default ResetPassword;
