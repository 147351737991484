import React, { useEffect } from "react";
import SideMenu from "../../components/SideMenu";
import "./inbox-styles.css";
import { Button } from "react-bootstrap";
import { EnvelopeOpen } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { userLoggedIn } from "../../utils/HelperMixins";

function Inbox() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!userLoggedIn()) {
      navigate("/login");
    }
    document.title = "Inbox";
  }, [navigate]);
  return (
    <div className="col-md-12">
      <div className="row mr-0">
        <SideMenu />
        <div className="col-md-10 main-container fixed-container">
          <div className="inbox-content-wrapper">
            <h2 className="sora-bold text-center">Your Inbox</h2>
            <div className="inbox-content">
              <div className="message-action">
                <Button
                  variant="primary"
                  className="action-btn submit-btn p-3 sora-regular"
                >
                  <span className="btn-text">Mark All Read </span>{" "}
                  <EnvelopeOpen size={18} />
                </Button>
              </div>
              <div className="message-wrapper">
                <div className="new-message-dot"></div>
                <div className="message-type sora-bold">New Feature</div>
                <div className="message-type sora-regular">
                  New integrations are her wohooo! you can now use Telegram....
                </div>
              </div>
              <div className="message-wrapper">
                <div className="new-message-dot"></div>
                <div className="message-type sora-bold">New Feature</div>
                <div className="message-type sora-regular">
                  New integrations are her wohooo! you can now use Telegram....
                </div>
              </div>
              <div className="message-wrapper">
                <div className="new-message-dot"></div>
                <div className="message-type sora-bold">New Feature</div>
                <div className="message-type sora-regular">
                  New integrations are her wohooo! you can now use Telegram....
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Inbox;
