import React from "react";
import {
  Globe,
  Instagram,
  Messenger,
  PhoneFill,
  Twitter,
  Whatsapp,
} from "react-bootstrap-icons";

function AgentChannels() {
  return (
    <div className="channels-wrapper">
      <div className="channel">
        <div className="icon-wrapper">
          <Globe size={100} color="#0000ff" />
        </div>
        <div className="channel-info">
          <div className="channel-name sora-bold">Website</div>
          <div className="channel-description sora-regular">
            Chat with your bot
          </div>
        </div>
      </div>
      <div className="channel">
        <div className="icon-wrapper">
          <PhoneFill size={100} color="#E1306C" />
        </div>
        <div className="channel-info">
          <div className="channel-name sora-bold">Mobile App</div>
          <div className="channel-description sora-regular">
            Chat with your bot
          </div>
        </div>
      </div>
      <div className="channel">
        <div className="icon-wrapper">
          <Messenger size={100} color="#0695FF" />
        </div>
        <div className="channel-info">
          <div className="channel-name sora-bold">Messenger</div>
          <div className="channel-description sora-regular">
            Chat with your bot
          </div>
        </div>
      </div>
      <div className="channel">
        <div className="icon-wrapper">
          <Whatsapp size={100} color="#25D366" />
        </div>
        <div className="channel-info">
          <div className="channel-name sora-bold">Whatsapp</div>
          <div className="channel-description sora-regular">
            Chat with your bot
          </div>
        </div>
      </div>
      <div className="channel">
        <div className="icon-wrapper">
          <Twitter size={100} color="#1DA1F2" />
        </div>
        <div className="channel-info">
          <div className="channel-name sora-bold">Twitter</div>
          <div className="channel-description sora-regular">
            Chat with your bot
          </div>
        </div>
      </div>
      <div className="channel">
        <div className="icon-wrapper">
          <Instagram size={100} color="#C13584" />
        </div>
        <div className="channel-info">
          <div className="channel-name sora-bold">Instagram</div>
          <div className="channel-description sora-regular">
            Chat with your bot
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentChannels;
