import React, { useEffect, useState } from "react";
import SideMenu from "../../components/SideMenu";
import {
  Robot,
  FileEarmarkBarGraph,
  NodePlusFill,
  GearWideConnected,
  BarChartLineFill,
} from "react-bootstrap-icons";
import Button from "react-bootstrap/esm/Button";
import AgentDataSet from "./AgentDataSet";
import AgentChannels from "./AgentChannels";
import AgentIntegrations from "./AgentIntegrations";
import AgentAnalytics from "./AgentAnalytics";
import useAgent from "../../hooks/useAgent";
import { Agent } from "./utils";
import { useLocation } from "react-router-dom";
import { AgentIdType } from "../../hooks/useAgent/utils";

function AgentManagement() {
  const { loading, getAgentDetails } = useAgent();
  const [agent, setAgent] = useState<Agent>({
    UserId: "",
    AgentId: "",
    Name: "",
    Description: "",
    CreatedDate: "",
    Status: "",
    CloudFormationStatus: "",
    Integrations: [],
    Analytics: {},
    Resources: {
      StackName: "",
      BotDescription: "",
      BotName: "",
      KendraIndexName: "",
      S3BucketName: "",
    },
    Files: [],
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const agentId = queryParams.get("agent");
  useEffect(() => {
    document.title = "Agent Management";
  }, []);
  useEffect(() => {
    getAgentDetailsRequest();
  }, []);
  const getAgentDetailsRequest = async () => {
    if (agentId !== null) {
      const data: AgentIdType = {
        AgentId: agentId,
      };
      const agentDetail = await getAgentDetails(data);
      if (agentDetail) setAgent(agentDetail.agent);
    }
  };
  const [tools] = useState<string[]>([
    "Data Upload",
    "Channels",
    "Integrations",
    "Analytics",
  ]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const selectTool = (index: number) => {
    setSelectedIndex(index);
  };
  return (
    <div className="col-md-12">
      <div className="row mr-0">
        <SideMenu />
        <div className="col-md-10 main-container fixed-container agent-management-main-padding">
          {!loading ? (
            <div className="agent-management-wrapper">
              <div className="agent-title">
                <Robot size={40} color="#2f95dc" />
                <div className="agent-name-management sora-bold">
                  {agent.Name}
                </div>
              </div>
              <div className="management-tools">
                {tools.map((tool, index) => {
                  return (
                    <Button
                      key={index}
                      className={
                        index === selectedIndex
                          ? "tools-list-active"
                          : "tools-list"
                      }
                      onClick={() => selectTool(index)}
                    >
                      {index === 0 && (
                        <FileEarmarkBarGraph size={20} className="tool-icon" />
                      )}
                      {index === 1 && (
                        <NodePlusFill size={20} className="tool-icon" />
                      )}
                      {index === 2 && (
                        <GearWideConnected size={20} className="tool-icon" />
                      )}
                      {index === 3 && (
                        <BarChartLineFill size={20} className="tool-icon" />
                      )}
                      {tool}
                    </Button>
                  );
                })}
              </div>
              {selectedIndex === 0 && (
                <AgentDataSet
                  agent={agent}
                  getAgentDetailsRequest={getAgentDetailsRequest}
                />
              )}
              {selectedIndex === 1 && <AgentChannels />}
              {selectedIndex === 2 && <AgentIntegrations />}
              {selectedIndex === 3 && <AgentAnalytics />}
            </div>
          ) : (
            <div className="text-center">
              <div className="spinner-border text-dark" role="status"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AgentManagement;
