import React, { useEffect, useState } from "react";
import SideMenu from "../../components/SideMenu";
import Button from "react-bootstrap/esm/Button";
import {
  PlusCircleFill,
  PencilSquare,
  Robot,
  Trash3,
} from "react-bootstrap-icons";
import AddAgentModal from "../../components/Modal/AddAgentModal";
import { useNavigate } from "react-router-dom";
import { userLoggedIn } from "../../utils/HelperMixins";
import useAgent from "../../hooks/useAgent";
import { Agent } from "./utils";
import { AGENT_STATUS, CLOUD_FORMATION_STATUS } from "../../constants/config";
import Swal from "sweetalert2";

function Agents() {
  const navigate = useNavigate();
  const { loading, removeLoading, getAgent, removeAgent } = useAgent();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [agent, setAgent] = useState<Agent>({
    UserId: "",
    AgentId: "",
    Name: "",
    Description: "",
    CreatedDate: "",
    Status: "",
    CloudFormationStatus: "",
    Integrations: [],
    Analytics: {},
    Resources: {
      StackName: "",
      BotDescription: "",
      BotName: "",
      KendraIndexName: "",
      S3BucketName: "",
    },
    Files: [],
  });
  useEffect(() => {
    if (!userLoggedIn()) {
      navigate("/login");
    }
    document.title = "Agent";
  }, [navigate]);
  useEffect(() => {
    getAgentRequest(true);
  }, []);
  useEffect(() => {
    const interval = setInterval(async () => {
      await getAgentRequest(false);
      if (agent.CloudFormationStatus !== "in-progress") {
        clearInterval(interval);
      }
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  const getAgentRequest = async (shouldLoad: boolean) => {
    const res = await getAgent(shouldLoad);
    if (res) {
      setAgent(res.agent);
    }
  };
  const toAgentManagement = () => {
    navigate("/agent-management?agent=" + agent.AgentId);
  };
  const handleRemoveAgent = async (agentId: string) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const data = {
          AgentId: agentId,
        };
        const res = await removeAgent(data);
        if (res) {
          setAgent({
            UserId: "",
            AgentId: "",
            Name: "",
            Description: "",
            CreatedDate: "",
            Status: "",
            CloudFormationStatus: "",
            Integrations: [],
            Analytics: {},
            Resources: {
              StackName: "",
              BotDescription: "",
              BotName: "",
              KendraIndexName: "",
              S3BucketName: "",
            },
            Files: [],
          });
          getAgentRequest(false);
        }
      }
    });
  };
  return (
    <div className="col-md-12">
      <div className="row mr-0">
        <SideMenu />
        <div className="col-md-10 main-container fixed-container">
          {agent?.AgentId === "" && (
            <div className="create-btn-wrapper">
              <Button
                variant="primary"
                onClick={() => setShowModal(true)}
                className="submit-btn p-3 sora-regular"
              >
                Create Agent <PlusCircleFill size={15} />
              </Button>
            </div>
          )}
          {/* This code can be help full in multiple agents*/}
          {/* <div className="agent-list-header">
            <div className="agent-icon"></div>
            <div className="agent-name sora-bold">Name</div>
            <div className="agent-descriptoin sora-bold">Description</div>
            <div className="agent-created-date sora-bold">Date Created</div>
            <div className="agent-status sora-bold">Status</div>
            <div className="agent-manage-btn-wrapper sora-bold">Action</div>
          </div> */}
          {!loading ? (
            <>
              {agent?.AgentId !== "" ? (
                <div className="agent-main-content-wrapper">
                  <div className="agent-wrapper">
                    <div className="agent-icon verticle-agent justify-content-center">
                      <Robot size={100} color="#2f95dc" />
                    </div>
                    <div className="agent-name sora-regular verticle-agent">
                      <div className="field-title">
                        <b>Agent Name</b>
                      </div>
                      <div className="agent-vertical-value">{agent.Name}</div>
                    </div>
                    <div className="agent-descriptoin sora-regular verticle-agent">
                      <div className="field-title">
                        <b>Agent Description</b>
                      </div>
                      <div className="agent-verical-value">
                        {agent.Description}
                      </div>
                    </div>
                    <div className="agent-created-date sora-regular verticle-agent">
                      <div className="field-title">
                        <b>Date Created</b>
                      </div>
                      <div className="agent-verical-value">
                        {agent.CreatedDate}
                      </div>
                    </div>
                    <div className="agent-status sora-regular verticle-agent ">
                      <div className="field-title">
                        <b>Status</b>
                      </div>
                      <div
                        className={
                          agent.Status === AGENT_STATUS.available
                            ? "agent-verical-value available"
                            : "agent-verical-value in-progress"
                        }
                      >
                        {agent.Status}
                      </div>
                    </div>
                    <div className="agent-manage-btn-wrapper">
                      <Button
                        onClick={toAgentManagement}
                        className="submit-btn p-3 w-48 sora-regular action-btn"
                        disabled={
                          agent.CloudFormationStatus ===
                          CLOUD_FORMATION_STATUS.inProgress
                        }
                      >
                        Manage Agent
                        <PencilSquare
                          size={25}
                          color="#ffffff"
                          className="action-btn"
                        />
                      </Button>
                      <Button
                        variant="danger"
                        className=" p-3 w-48 sora-regular action-btn"
                        onClick={() => handleRemoveAgent(agent.AgentId)}
                        disabled={
                          agent.CloudFormationStatus ===
                          CLOUD_FORMATION_STATUS.inProgress
                        }
                      >
                        {!removeLoading ? (
                          <span className="d-flex align-items-center justify-content-center">
                            Remove{" "}
                            <Trash3
                              size={25}
                              color="#ffffff"
                              className="action-btn"
                            />
                          </span>
                        ) : (
                          <div
                            className="spinner-border text-light"
                            role="status"
                          ></div>
                        )}
                      </Button>
                    </div>
                    {agent.CloudFormationStatus ===
                      CLOUD_FORMATION_STATUS.inProgress && (
                      <div className="text-center mt-3">
                        <div className="d-flex align-items-center justify-content-center">
                          <div
                            className="spinner-grow spinner-grow-sm mr-1"
                            style={{ width: 15, height: 15 }}
                            role="status"
                          ></div>
                          <div
                            className="spinner-grow spinner-grow-sm mr-1"
                            style={{ width: 15, height: 15 }}
                            role="status"
                          ></div>
                          <div
                            className="spinner-grow spinner-grow-sm mr-1"
                            style={{ width: 15, height: 15 }}
                            role="status"
                          ></div>
                        </div>
                        <div className="agent-creation sora-regular mt-2">
                          Setting up things for the agent
                        </div>
                        <div className="agent-creation-time sora-regular mt-1">
                          This might take a few minutes
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="text-center">
                  <div className="no-agent sora-regular">No Agent Added</div>
                </div>
              )}
            </>
          ) : (
            <div className="text-center">
              <div className="spinner-border text-dark" role="status"></div>
            </div>
          )}
        </div>
      </div>
      <AddAgentModal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          getAgentRequest(true);
        }}
      />
    </div>
  );
}

export default Agents;
