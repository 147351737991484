import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "../pages/Login";
import SignUp from "../pages/SignUp";
import Dashboard from "../pages/Dashboard";
import Profile from "../pages/Profile";
import Agents from "../pages/Agents";
import Inbox from "../pages/Inbox";
import HelpAndSupport from "../pages/HelpAndSupport";
import Settings from "../pages/Settings";
import ResetPassword from "../pages/ResetPassword";
import AgentManagement from "../pages/Agents/AgentManagement";
export default function Routers() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Login Screen */}
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        {/* Signup Screen */}
        <Route path="/signup" element={<SignUp />} />
        {/* Forget Password Screen */}
        <Route path="/reset-passeord" element={<ResetPassword />} />
        {/* Dashboard Screen */}
        <Route path="/dashboard" element={<Dashboard />} />
        {/* Profile Screen */}
        <Route path="/profile" element={<Profile />} />
        {/* Agents Screens */}
        <Route path="/agent" element={<Agents />} />
        <Route path="/agent-management" element={<AgentManagement />} />
        {/* Inbox Screen */}
        <Route path="/inbox" element={<Inbox />} />
        {/* HelpAndSupport Screen */}
        <Route path="/help-and-support" element={<HelpAndSupport />} />
        {/* Settings Screen */}
        <Route path="/settings" element={<Settings />} />
      </Routes>
    </BrowserRouter>
  );
}
