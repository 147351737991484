import { httpGet, httpPost } from "./../../axios/axiosUtils";
import { useState } from "react";
import { User } from "../../shared/types";
import useProgressState from "../useProgressState";
import LocalStorageComponent from "../../components/LocalStorageComponents";
import {
  SignInProps,
  SignUpProps,
  UpdatePassword,
} from "../../context/auth/types";
import { errorAlert, successAlert } from "../../utils/HelperMixins";
import {
  SignInResponse,
  SignOutResponse,
  SignUpResponse,
  RefreshResponse,
  UpdatePasswordResponse,
} from "../../pages/Login/utils";
const useAuth = () => {
  const { setFailure, setLoading, setSuccess, success, failure, loading } =
    useProgressState();
  // const navigate = useNavigate();
  const [userData, setUserData] = useState<User | undefined>(undefined);
  const signUp = async (data: SignUpProps) => {
    try {
      setLoading();
      const response: SignUpResponse = await httpPost("/user/register", data);
      successAlert(response.message);
      setSuccess();
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setFailure();
      errorAlert(error.response.data.message);
      return false;
    }
  };
  const signIn = async (data: SignInProps) => {
    try {
      setLoading();
      const response: SignInResponse = await httpPost("/user/auth/login", data);
      LocalStorageComponent.setItemLocally("token", response.token);
      setSuccess();
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setFailure();
      errorAlert(error?.response.data.message);
      return false;
    }
  };

  const signOut = async () => {
    try {
      try {
        setLoading();
        const response: SignOutResponse = await httpGet(
          "/user/auth/signout",
          {},
        );
        successAlert(response.message);
        setSuccess();
        return true;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setFailure();
        errorAlert(error.response.data.message);
        return false;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setFailure();
      errorAlert(error.response.data.message);
      return false;
    }
  };
  const refresh = async () => {
    try {
      try {
        setLoading();
        const response: RefreshResponse = await httpGet("/user/auth/refresh");
        if (response.status === 200) {
          setSuccess();
          return true;
        }
        return false;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setFailure();
        errorAlert(error.response.data.message);
        return false;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setFailure();
      errorAlert(error.response.data.message);
      return false;
    }
  };
  const updatePassord = async (data: UpdatePassword) => {
    try {
      try {
        setLoading();
        const response: UpdatePasswordResponse = await httpPost(
          "/user/auth/updateUserPassword",
          data,
        );
        successAlert(response.message);
        setSuccess();
        return true;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setFailure();
        errorAlert(error.response.data.message);
        return false;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setFailure();
      errorAlert(error.response.data.message);
      if (error.response.status === 401) {
        LocalStorageComponent.deleteAll();
        // navigate('/login');
      }
      return false;
    }
  };

  const getProfileData = async () => {
    try {
      setLoading();
      const data = LocalStorageComponent.getItemLocally("user");
      if (data) {
        const user = JSON.parse(data);
        setUserData(user);
      }
      setSuccess();
    } catch (err) {
      setFailure();
    }
  };
  return {
    success,
    failure,
    loading,
    userData,
    signUp,
    signIn,
    signOut,
    refresh,
    updatePassord,
    getProfileData,
  };
};

export default useAuth;
